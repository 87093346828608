<template>
  <div>
    <div v-if="mode != 'yearly'">
      <b-input-group
        class="mb-md-0 mb-3"
        @click="handleClick"
      >
        <template #prepend>
          <b-input-group-text
            class="prepend-outline-secondary"
            style="background-color: transparent;"
          >
            {{ label }}
          </b-input-group-text>
        </template>
        <flat-pickr
          v-model="displayValue"
          name="date"
          :config="configDatePicker"
          class="form-control has-prepend has-icon-append bg-none"
          :placeholder="placeholder"
          @on-change="setSelectedDate"
        />
        <div class="d-flex icon-input-append h-100 px-3">
          <span
            aria-hidden="true"
            class="sr-only"
          >Toggle</span>
          <i class="my-auto las la-calendar" />
        </div>
      </b-input-group>
      <el-date-picker
        v-model="localValue" 
        class="element-picker"
        :type="datePickerMode"
        unlink-panels
        :editable="false"
        :clearable="false"
      />
    </div>
    <YearRangePicker
      v-else
      @change="setYear"
    />
  </div>
</template>

<script>
import { format, endOfMonth } from 'date-fns';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import YearRangePicker from '@/components/YearRangePicker.vue';

export default {
  components: {
    flatPickr, YearRangePicker
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return [new Date(), new Date()]
      }
    },
    label: {
      type: String,
      default: 'Date'
    },
    placeholder: {
      type: String,
      default: 'Select Date'
    },
    mode: {
      type: String,
      default: 'daterange'
    },
    altFormat: {
      type: String,
      default: 'j M Y  H:i'
    }
  },
  data() {
    return {
      localValue: [new Date(), new Date()],
      displayValue: [],
    }
  },
  computed: {
    configDatePicker() {
      let endToday = new Date();
      endToday.setHours(23, 59, 0, 0);
      return {
        mode: 'range',
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.altFormat,
        altInput: true,
        disableMobile: 'true',
        enableTime: false,
        clickOpens: false,
        time_24hr: true,
      };
    },
    datePickerMode() {
      if(this.mode == 'monthly') {
        return 'monthrange'
      } return 'daterange'
    }
  },
  watch: {
    mode: {
      immediate: true,
      handler() {
        this.localValue = this.modelValue
      }
    },
    localValue: {
      immediate: true,
      handler() {
        this.displayValue = [format((this.localValue[0]), 'yyyy-MM-dd'), format(this.localValue[1], 'yyyy-MM-dd')]
      }
    },
  },
  methods: {
    setSelectedDate(selectedDates) {
      if(this.mode == 'monthly') {
        const end = new Date(new Date(endOfMonth(selectedDates[1])).setHours(23, 59, 59))
        this.$emit('update:modelValue', [selectedDates[0], end])
      } else {
        const end = new Date(selectedDates[1].setHours(23, 59, 59))
        this.$emit('update:modelValue', [selectedDates[0], end])
      }
    },
    setYear(selectedYears) {
      if(selectedYears.length == 2) {
        this.$emit('update:modelValue', selectedYears)
      }
    },
    handleClick() {
      const element = document.querySelectorAll('.element-picker')
      element.forEach(item => item.click())
    }
  },
}
</script>
<style>
.element-picker {
  height: 0px !important;
  width: 0px !important;
  left: 50% !important;
  top: -20px !important;
  z-index: -1 !important;
}
</style>
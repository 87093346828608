<template>
  <vue3-chart-js
    v-if="!refresh"
    v-bind="{ ...chartSettings }"
  />
</template>
  
<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom'

Vue3ChartJs.registerGlobalPlugins([ChartDataLabels])
Vue3ChartJs.registerGlobalPlugins([zoomPlugin])

export default {
  components: {
    Vue3ChartJs,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    xLabel: {
      type: String,
      default: ''
    },
    yLabel: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      refresh: false,
    }
  },
  computed: {
    dataLabel() {
      if (this.data.length) {
        return this.data.map(item => item.label)
      } return []
    },
    dataValue() {
      if (this.data.length) {
        return this.data.map(item => item.value)
      } return []
    },
    yMax() {
      const maxData = Math.max(...this.dataValue)
      return maxData*1.1
    },
    chartSettings() {
      return {
        type: "bar",
        options: {
          events: [],
          responsive: true,
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                mode: "x",
              },
              pan: {
                enabled: true,
                mode: "x",
              },
            },
            legend: {
              display: false,
            },
            datalabels: {
              color: '#000000',
              anchor: 'end',
              align: 'top',
              clip: true,
              formatter: function(value, context) {
                return value.toFixed(2);
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false
              },
              title: {
                display: true,
                text: this.xLabel,
              },
            },
            y: {
              min: 0,
              suggestedMax: this.yMax,
              title: {
                display: true,
                text: this.yLabel,
              }
            },
          },
        },
        data: {
          labels: this.dataLabel,
          datasets: [{
            backgroundColor: ["#3577F1"],
            data: this.dataValue,
          },],
        },
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh = true
        setTimeout(() => {
          this.refresh = false
        }, 1);
      }
    }
  },
};
</script>  
<template>
  <div>
    <b-input-group
      class="mb-md-0 mb-3"
      @click="handleClick"
    >
      <template #prepend>
        <b-input-group-text
          class="prepend-outline-secondary"
          style="background-color: transparent;"
        >
          {{ label }}
        </b-input-group-text>
      </template>
      <flat-pickr
        v-model="displayValue"
        name="date"
        :config="configDatePicker"
        class="form-control has-prepend has-icon-append bg-none"
        :placeholder="placeholder"
        @on-change="setSelectedDate"
      />
      <div class="d-flex icon-input-append h-100 px-3">
        <span
          aria-hidden="true"
          class="sr-only"
        >Toggle</span>
        <i class="my-auto las la-calendar" />
      </div>
    </b-input-group>
    <el-date-picker
      v-model="localValue" 
      class="element-picker"
      popper-class="year-picker-popper"
      type="monthrange"
      unlink-panels
      :editable="false"
      :clearable="false"
    />
  </div>
</template>

<script>
import { format, startOfYear, addYears, endOfYear } from 'date-fns';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    flatPickr,
  },
  props: {
    label: {
      type: String,
      default: 'Date'
    },
    placeholder: {
      type: String,
      default: 'Select Date'
    },
    mode: {
      type: String,
      default: 'daterange'
    },
  },
  data() {
    return {
      localValue: [new Date(), new Date()],
      displayValue: [],
    }
  },
  computed: {
    configDatePicker() {
      let endToday = new Date();
      endToday.setHours(23, 59, 0, 0);
      return {
        mode: 'range',
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'Y',
        altInput: true,
        // maxDate: format(endToday, "yyyy-MM-dd HH:mm"),
        disableMobile: 'true',
        enableTime: false,
        clickOpens: false,
        time_24hr: true,
      };
    },
    monthList() {
      return [
        { key: 'January', value: -5},
        { key: 'February', value: -4},
        { key: 'March', value: -3},
        { key: 'April', value: -2},
        { key: 'May', value: -1},
        { key: 'June', value: 0},
        { key: 'July', value: 1},
        { key: 'August', value: 2},
        { key: 'September', value: 3},
        { key: 'October', value: 4},
        { key: 'November', value: 5},
        { key: 'December', value: 6}
      ]
    } 
  },
  watch: {
    mode: {
      immediate: true,
      handler() {
        this.localValue = [new Date().setMonth(5), new Date().setMonth(5)]
      }
    },
    localValue: {
      immediate: true,
      handler() {
        const startYear = startOfYear(new Date())
        const startIndex = Number(format((this.localValue[0]), 'M')) - 1
        const start = addYears(startYear, this.monthList[startIndex].value)  

        const endYear = endOfYear(new Date())
        const endIndex = Number(format((this.localValue[1]), 'M')) - 1
        const end = addYears(endYear, this.monthList[endIndex].value) 

        this.displayValue = [start, end]
      }
    },
  },
  mounted () {
    this.spoofMonthPicker();
    this.localValue = [new Date().setMonth(5), new Date().setMonth(5)]
  },
  methods: {
    setSelectedDate(selectedDates) {
      this.$emit('change', [selectedDates[0], selectedDates[1]])
    },
    handleClick() {
      const element = document.querySelectorAll('.element-picker')
      element.forEach(item => item.click())
    },
    spoofMonthPicker() {
      // Change month label to year
      this.monthList.forEach(month => { 
        const selector = `[aria-label="${month.key}"]`;
        const cell = document.querySelector(selector).querySelector('.cell');
        cell.textContent = new Date().getFullYear() + month.value
      });

      // Hide header button
      const popper = document.querySelector('.year-picker-popper')
      const button = popper.querySelectorAll('.el-picker-panel__icon-btn')
      button.forEach(item => item.style.display = 'none')
      // Change header label
      const header = popper.querySelectorAll('.el-date-range-picker__header')
      header.forEach(item => (item.style.marginBottom = '1rem', item.style.fontSize = '16px'))
      header.forEach(item => item.textContent = 'Select Year Range')
      // Hide right panel calendar, stretch left panel calendar
      popper.querySelector('.is-right').style.display = 'none'
      popper.querySelector('.is-left').style.width = '100%'
      // Remove class from current month
      const currentMonth = popper.querySelectorAll('.today')
      currentMonth[0].classList.remove('today')
    }
  },
}
</script>
<template>
  <div>
    <div v-if="isEnabled">
      <b-button
        :variant="buttonVariant"
        class="flex-shrink-0"
        @click="downloadExcel"
      > 
        <slot name="button-content">
          <i class="bx bx-download" />
          CSV
        </slot>
      </b-button>
      <download-excel
        class="d-none"
        :data="data" 
        type="csv"
        :name="`${fileName}.csv`"
        :escape-csv="false"
      >
        <div ref="download-excel" />
      </download-excel>
    </div>
    <div
      v-else
      class="d-flex"
    >
      <Popper
        :hover="true"
        class="border-0 w-100 m-auto"
        placement="top"
      >
        <template #content>
          <slot name="popper-content">
            First of all, you need to load all data location.
            <br>
            Please click the "Load More" button until the last step
          </slot>
        </template>
        <b-button
          variant="soft-primary disabled"
          class="flex-shrink-0"
          disabled
        > 
          <slot name="button-content">
            <i class="bx bx-download" />
            CSV
          </slot>
        </b-button>
      </Popper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    fileName: {
      type: String,
      default: 'data'
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    buttonVariant: {
      type: String,
      default: 'info'
    },
  },
  methods: {
    downloadExcel() {
      this.$refs['download-excel'].click()
    }
  },
}
</script>
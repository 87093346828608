<template>
  <div>
    <b-input-group
      class="mb-md-0 mb-3"
    >
      <template #prepend>
        <b-input-group-text
          class="prepend-outline-secondary"
          style="background-color: transparent; z-index: 3;"
        >
          Fleet
        </b-input-group-text>
      </template>
      <div
        class="form-control search-input pointer-hover"
        style="border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
      >
        {{ label }}
      </div>
      <b-input-group-append>
        <span
          class="custom-caret pointer-hover" 
          :class="dropdownIsOpen == 'true' ? 'is-open' : ''"
          style="margin-left: -32px;"
        >
          <i class="bx bx-chevron-down my-auto fs-4" />
        </span>
      </b-input-group-append>
      <div class="dropdown w-100">
        <div
          ref="emission-dropdown"
          type="button"
          class="btn shadow-none position-absolute"
          style="bottom: -100%; width: 99%; left: 1%;"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          &nbsp;
        </div>
        <div
          id="dropdown-emission"
          class="dropdown-menu w-100 py-1"
          @click.native.stop
        >
          <!-- Searchbar -->
          <div class="px-3 my-0 py-3">
            <div class="d-flex justify-content-between">
              <b-input-group>
                <b-form-input
                  v-model="searchKeyword"
                  class="form-control search-input"
                  placeholder="Search"
                  style="border-radius: 5px;"
                  @keydown.enter="getFleetList"
                />
                <b-input-group-append>
                  <div
                    class="d-flex cancel-search-icon h-100 px-3"
                    style="position: relative;"
                    @click="resetSearch"
                  >
                    <i
                      class="my-auto" 
                      :class="searchKeyword ? 'ri-close-line' : 'ri-search-line'"
                    />
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <!-- Item -->
          <div
            class="d-flex justify-content-between px-3 py-2"
            style="border-top: 1px solid #CED4DA; border-bottom: 1px solid #CED4DA;" 
            @click.native.stop
          >
            <div class="fw-bold">
              Vehicle Number
            </div>
            <div
              class="custom-link pointer-hover"
              @click.native.stop="selectedFleet = []"
            >
              Reset
            </div>
          </div>
          <div
            class="overflow-auto"
            style="max-height: 400px;"
          >
            <a
              v-for="(fleet, index) in selectedFleet"
              :key="index"
              class="py-2 pointer-hover dropdown-item active-dropdown-item"
              @click.native.stop="deselectFleet(fleet)"
            >
              <div class="d-flex justify-content-between">
                <span>{{ fleet.vehicle_number }}</span>
                <i class="bx bx-check fs-18" />
              </div>
            </a>
            <a
              v-for="(fleet, index) in notSelectedFleet"
              :key="index"
              class="py-2 pointer-hover dropdown-item"
              @click.native.stop="selectedFleet.push(fleet)"
            >{{ fleet.vehicle_number }}</a>
          </div>
        </div>
      </div>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    canBusAvailable: {
      type: [Number, String],
      default: 1
    },
  },
  data() {
    return {
      searchKeyword: '',
      dropdownIsOpen: false,
      fleetList: [],
      selectedFleet: [],
    }
  },
  computed: {    
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
    notSelectedFleet() {
      return this.fleetList.filter(fleet => !this.selectedFleet.includes(fleet))
    },
    label() {
      if(this.selectedFleet.length) {
        return `Selected (${this.selectedFleet.length})`
      } return `All (${this.fleetList.length})`
    }
  },
  watch: {
    selectedOrganization: {
      immediate: true,
      handler(organizationId) {
        if (organizationId) {
          this.getFleetList()
        }
      },
    },
    canBusAvailable: {
      immediate: true,
      handler() {
        this.getFleetList()
      },
    },
    selectedFleet: {
      deep: true,
      immediate: false,
      handler(value) {
        const selectedId = value.map(item => item.id) ?? []
        this.$emit('filter', selectedId)
      }
    }
  },
  mounted() {
    const targetElement = this.$refs['emission-dropdown'];
    const observer = new MutationObserver(mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
          this.dropdownIsOpen = targetElement.getAttribute('aria-expanded');
        }
      }
    });    
    // Start observing dropdown open / close
    observer.observe(targetElement, { attributes: true });
  },
  methods: {
    async getFleetList() {      
      try {
        this.selectedFleet = []
        const { data } = await this.$api.getOrganizationDevices(this.selectedOrganization, { keyword: this.searchKeyword, can_bus_available: this.canBusAvailable, per_page: 200 });
        this.fleetList = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    deselectFleet(fleet) {
      const item = this.selectedFleet.find(item => item == fleet)
      this.selectedFleet = this.selectedFleet.filter(fleet => fleet != item)
    },
    resetSearch() {
      this.searchKeyword = null
      this.getFleetList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer-hover:hover {
  cursor: pointer;
}
.active-dropdown-item {
  background-color: #F3F6F9;
}
.active-dropdown-item:hover {
  background-color: #e9ebec;
}
.is-open {
  transform: rotate(180deg);
  transition:transform .3s
}

</style>